import { FunctionComponent } from "react"
import { StyledAbsoluteBox, StyledBox, StyledInputText, StyledStix, StyledTxt } from "./styled"
import { SelectChangeEvent } from "@mui/material/Select"
import { SelectOptions } from "./SelectOptions"
import { APPRAISAL_RESULTS, AppraisalResultType, STATE_RESULTS, StateResultType } from "./common"

const COLUMN_HEIGHT=8
const CALIBER_LABEL = "Caliber"
const DETAIL_NOTE_TEXT = "정밀 감정진단 시에만 해당";

export type AppraisalCertificateInteriorType = {
  label: string
  labelKo:string
  key: string
  value: StateResultType | ""
  result: AppraisalResultType | string
}
export const DiagnosisPrintInterior:FunctionComponent<{
  items: AppraisalCertificateInteriorType[]
  isDetail:boolean
  editable:boolean
  descriptionFontSize:number
  handleStateChange:(label:string, value:string, state:string) => void
  handleValueChange:(label:string, value:string, state:string) => void
}> = ({
  items,
  handleStateChange,
  handleValueChange,
  isDetail,
  editable,
  descriptionFontSize,
}) => {
  const DetailNote = () => (
    <StyledBox $gap={19}  $width="100%" $justifyContent={"center"} $paddingLeft={12} style={{
      position: "absolute",
      top: "50%",
      transform:"translateY(-50%)",
      left:0
    }}>
      <StyledBox $width={74} $flexShrink={0}>&nbsp;</StyledBox>
      <StyledBox $width={"100%"} $justifyContent="center">
        <StyledTxt $fontSize={descriptionFontSize} $lineHeight={COLUMN_HEIGHT} $weight={500} $color="#000">{DETAIL_NOTE_TEXT}</StyledTxt>
      </StyledBox>
    </StyledBox>
  )

  return(
    items.length > 0 ? (
      <StyledBox $flexDirection="column" $paddingLeft={12} $marginBottom={13} >
        {
          items.map((interior, index) => (
            <InteriorItem
              editable={editable}
              isDetail={isDetail}
              key={index}
              item={interior}
              isFirst={index === 0}
              isLast={index === items.length-1}
              itemStateChange={(event) =>{
                interior.result && interior.value && handleStateChange(interior.label, interior.value, event.target.value)
              }}
              itemValueChange={(event) => {
                interior.result && interior.value && handleValueChange(interior.label, event.target.value, interior.result)
              }}
            />
          ))
        }
        {
          isDetail ? null : <DetailNote />
        }
      </StyledBox>
    ) : null
  )
}


const InteriorItem:FunctionComponent<{
  item: AppraisalCertificateInteriorType
  itemStateChange:(event:SelectChangeEvent)=>void
  itemValueChange:(event:SelectChangeEvent)=>void
  isDetail:boolean
  isFirst?:boolean
  isLast?:boolean
  editable:boolean
}>=(props)=>{
  return (
    <StyledBox $gap={19} $width={"100%"}  >
      <StyledBox
        $width={74}
        $flexShrink={0}
        $gap={2}
        $alignItems="center"
        $paddingTop={props.isFirst? 4 : 3}
        $paddingBottom={props.isLast? 4 : 0}
        className="is-design"
      >
        <StyledTxt $color="#000" $fontSize={7} $weight={700} $lineHeight={COLUMN_HEIGHT}>
          {props.item.label}
        </StyledTxt>
        <StyledTxt $color="#545454" $fontSize={5} $lineHeight={COLUMN_HEIGHT}>
          {props.item.labelKo}
        </StyledTxt>
      </StyledBox>

      <StyledBox
        $width={"100%"}
        $paddingTop={props.isFirst? 4 : 3}
        $paddingBottom={props.isLast? 4 : 0}
        $paddingLeft={9}
        $paddingRight={7}
        $isBg={true}
        $justifyContent={"space-between"}
      >
        {
          props.isDetail ? (
            <>
              <StyledBox $width={110} $height={COLUMN_HEIGHT} $justifyContent={"flex-start"}>
                {
                  props.item.label === CALIBER_LABEL ?
                    (
                      <StyledInputText
                        $color="#000"
                        $fontSize={6}
                        $lineHeight={COLUMN_HEIGHT}
                        value={props.item.value === null ? "" : props.item.value}
                        readOnly={!props.editable}
                        onChange={props.itemValueChange}
                        style={{paddingLeft:0}}
                      />
                    ) :
                    <>
                      <StyledStix
                        $fontSize={6}
                        $lineHeight={COLUMN_HEIGHT}
                        $color="#000"
                        className={props.item.value === "미노출" ? "not-printing" : ""}
                      >
                        {props.item.value}
                      </StyledStix>
                      {
                        props.editable ? (
                          <SelectBox
                            items={[...STATE_RESULTS]}
                            selectedIndex={STATE_RESULTS.findIndex(item => item === props.item.value)}
                            handleChange={props.itemValueChange}
                          />
                        ) : null
                      }
                    </>
                }

              </StyledBox>
              <StyledBox $width={65} $justifyContent={"center"}>
                <>
                  <StyledStix
                    $isItalic={true}
                    $color="#000"
                    $fontSize={6}
                    $lineHeight={COLUMN_HEIGHT}
                    $weight={600}
                    style={{
                      letterSpacing: 0.2
                    }}
                    className={props.item.result === "미노출" ? "not-printing" : ""}
                  >
                    {props.item.result}
                  </StyledStix>
                  {
                    props.editable ? (
                      <SelectBox
                        items={[...APPRAISAL_RESULTS]}
                        selectedIndex={APPRAISAL_RESULTS.findIndex(item => item === props.item.result)}
                        handleChange={props.itemStateChange}
                      />
                    ) : null
                  }
                </>
              </StyledBox>
            </>
          ) : (
            <StyledBox $width={"100%"} $height={COLUMN_HEIGHT} $isBg={true}></StyledBox>
          )
        }
      </StyledBox>
    </StyledBox>
  )
}

const SelectBox = ({ items, selectedIndex, handleChange } : {
  items:string[],
  selectedIndex:number,
  handleChange: (event:SelectChangeEvent) => void,
}) => {
  return (
    <StyledAbsoluteBox
      $width={"100%"}
      className="not-printing"
    >
      <SelectOptions
        items={items}
        selectedIndex={selectedIndex}
        handleChange={handleChange}
      />
    </StyledAbsoluteBox>
  )
}