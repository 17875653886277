import styled from "@emotion/styled"
import { KindOfTermsType, TermsAgreementType, UserDetailType } from "types/UserType"
import Button from "components/Button"
import DetailTableBody from "components/Table/Detail/TableBody"
import DetailTableRowCell from "components/Table/Detail/TableBody/TableCell/Row"
import * as React from "react"
import { useCallback, useState } from "react"
import _, { isEmpty } from "lodash"
import UserStatusChangeModal from "./StatusChangeModal"
import UserNickNameChangeModal from "./NicknameChangeModal"
import UserPhonenNumberChangeModal from "./PhoneNumberChangeModal"
import UserMemoChangeModal from "./MemoChangeModal"
import { useNavigate } from "react-router-dom"
import { toDateStr } from "../../../../../utils/datetimeUtil"
import { postSuperLogin, updateCustomsCode } from "../../../../../apis/userAPI"
import { toast } from "react-toastify"
import { serviceUrl } from "../../../../../utils/httpAPI"
import { TextField } from "@mui/material"
import CustomsCodeChangeModal from "./CustomsCodeChangeModal"

type Props = {
  user: UserDetailType
  userPoint: number
  changeNickname: (nickname: string) => void
  changePhoneNumber: (phoneNumber: string) => void
  saveMemo: (memo: string) => void
  deleteMemo: (memoId: number) => void
  setUserDetail: React.Dispatch<React.SetStateAction<UserDetailType>>
}

const UserInformationTable = ({
  user,
  userPoint,
  changeNickname,
  changePhoneNumber,
  saveMemo,
  deleteMemo,
  setUserDetail,
}: Props) => {
  const navigate = useNavigate()
  const [isUserStatusModalOpen, setIsUserStatusModalOpen] = useState(false)
  const [isUserNicnameChangeModalOpen, setIsUserNicknameChangeModalOpen] = useState(false)
  const [isUserPhoneNumberChangeModalOpen, setIsUserPhoneNumberChangeModalOpen] = useState(false)
  const [isUserMemoChangeModalOpen, setIsUserMemoChangeModalOpen] = useState(false)
  const [isUserCustomsCodeChangeModalOpen, setIsUserCustomsCodeChangeModalOpen] = useState(false)
  const [superLoginReason, setSuperLoginReason] = useState("")

  const parseTermsValue = (kindOfTerm: KindOfTermsType, parsingKey: keyof TermsAgreementType) => {
    if (!user.termsAgreement) {
      return
    }

    const parsedTerm = _.find(user.termsAgreement, (term) => term.terms.type === kindOfTerm)
    return parsedTerm?.[parsingKey]
  }

  const superLogin = (userId: number) => {
    if (isEmpty(superLoginReason)) {
      window.alert("회원 계정으로 로그인 시 사유 입력은\n필수입니다. 사유를 입력해 주세요.")
      return
    }

    if (
      window.confirm(
        "선택한 회원 계정으로 로그인합니다. 업무이외 용도로 사용 시 개인정보 유출에 따른 법적 처벌 대상이 될 수 있습니다.\n\n동의하시면 확인 버튼을 눌러주세요."
      )
    ) {
      setSuperLoginReason("")
      postSuperLogin(userId, superLoginReason)
        .then((verifyKey) => {
          window.open(`${serviceUrl}/login/repus?verifyKey=${verifyKey}`, "_blank")
        })
        .catch((error) => {
          toast.error(error.message)
        })
    }
  }

  const replaceBooleanValueToString = (boolean: boolean) => {
    return boolean ? "O" : "X"
  }

  if (!user.id) {
    return <></>
  }

  return (
    <>
      <DetailTableBody>
        <TitleWrapper>회원정보</TitleWrapper>
        <ColumnWrapper>
          <RowWrapper>
            <DetailTableRowCell titleWidth={"150px"} title="회원번호">
              <CellContentRowWrapper>
                {user.id}
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end" }}>
                  <SuperLoginInputWrapper>
                    <TextField
                      style={{ width: "240px" }}
                      InputProps={{
                        inputProps: {
                          style: { fontSize: 12 },
                        },
                      }}
                      placeholder={"로그인 사유 입력"}
                      size={"small"}
                      onChange={(e) => {
                        setSuperLoginReason(e.target.value)
                      }}
                    />
                  </SuperLoginInputWrapper>
                  <SuperLoginButtonWrapper>
                    <Button buttonStyle="blue-fill" buttonSize="S" onClick={() => superLogin(user.id)}>
                      회원 계정으로 로그인
                    </Button>
                  </SuperLoginButtonWrapper>
                </div>
              </CellContentRowWrapper>
            </DetailTableRowCell>
            <DetailTableRowCell titleWidth={"150px"} title="상태">
              <CellContentRowWrapper>
                {user.status}
                {/* 상세 기능 개발 후 추가 */}
                {/* <ButtonWrapper>
                  <Button buttonStyle="blue-fill" buttonSize="S" onClick={() => setIsUserStatusModalOpen(true)}>
                    관리
                  </Button>
                </ButtonWrapper> */}
              </CellContentRowWrapper>
            </DetailTableRowCell>
          </RowWrapper>

          <RowWrapper>
            <DetailTableRowCell titleWidth={"150px"} title="로그인 방식">
              {user.authType}
            </DetailTableRowCell>
            <DetailTableRowCell titleWidth={"150px"} title="회원가입일">
              {user.signupAt ? `${toDateStr(user.signupAt)}` : "-"}
            </DetailTableRowCell>
          </RowWrapper>
          <RowWrapper>
            <DetailTableRowCell titleWidth={"150px"} title="이메일">
              {user.email}
            </DetailTableRowCell>
            <DetailTableRowCell titleWidth={"150px"} title="약관동의일">
              {user.termsAgreement && toDateStr(parseTermsValue("이용약관", "updatedAt") as string)}
            </DetailTableRowCell>
          </RowWrapper>
          <RowWrapper>
            <DetailTableRowCell titleWidth={"150px"} title="닉네임">
              <CellContentRowWrapper>
                {user.nickname}
                {/*<ButtonWrapper>*/}
                {/*  <Button buttonStyle="blue-fill" buttonSize="S" onClick={() => setIsUserNicknameChangeModalOpen(true)}>*/}
                {/*    변경*/}
                {/*  </Button>*/}
                {/*</ButtonWrapper>*/}
              </CellContentRowWrapper>
            </DetailTableRowCell>
            <DetailTableRowCell titleWidth={"150px"} title="개인정보 수집·이용동의일">
              {user.termsAgreement && toDateStr(parseTermsValue("개인정보", "updatedAt") as string)}
            </DetailTableRowCell>
          </RowWrapper>
          <RowWrapper>
            <DetailTableRowCell titleWidth={"150px"} title="이름">
              {user.name}
            </DetailTableRowCell>
            <DetailTableRowCell titleWidth={"150px"} title="광고 수신 동의">
              {replaceBooleanValueToString(user.receiveAdPush)}
            </DetailTableRowCell>
          </RowWrapper>
          <RowWrapper>
            <DetailTableRowCell titleWidth={"150px"} title="연락처">
              <CellContentRowWrapper>
                {user.carrier} {user.phone}
                {/*<ButtonWrapper>*/}
                {/*  <Button*/}
                {/*    buttonStyle="blue-fill"*/}
                {/*    buttonSize="S"*/}
                {/*    onClick={() => setIsUserPhoneNumberChangeModalOpen(true)}*/}
                {/*  >*/}
                {/*    변경*/}
                {/*  </Button>*/}
                {/*</ButtonWrapper>*/}
              </CellContentRowWrapper>
            </DetailTableRowCell>
            <DetailTableRowCell titleWidth={"150px"} title="거래 알림 수신">
              {replaceBooleanValueToString(user.receiveOrderPush)}
            </DetailTableRowCell>
          </RowWrapper>
          <RowWrapper>
            <DetailTableRowCell titleWidth={"150px"} title="기본 주소">
              {user.address?.address} {user.address?.addressDetail}
            </DetailTableRowCell>
            <DetailTableRowCell titleWidth={"150px"} title="휴면전환 안내일">
              {user.dormantScheduledDate}
            </DetailTableRowCell>
          </RowWrapper>
          <RowWrapper>
            <DetailTableRowCell titleWidth={"150px"} title="바이버 페이">
              {replaceBooleanValueToString(user.hasViverPayDefaultCard)}
            </DetailTableRowCell>
            <DetailTableRowCell titleWidth={"150px"} title="포인트">
              <CellContentRowWrapper>
                {userPoint}
                <ButtonWrapper>
                  <Button buttonStyle="blue-fill" buttonSize="S" onClick={() => navigate(`/points/${user.id}`)}>
                    관리
                  </Button>
                </ButtonWrapper>
              </CellContentRowWrapper>
            </DetailTableRowCell>
          </RowWrapper>
          <RowWrapper>
            <DetailTableRowCell titleWidth={"150px"} title="정산계좌">
              {user.bankAccount?.bank.name} {user.bankAccount?.accountNumber} {user.bankAccount?.accountHolder}
            </DetailTableRowCell>
            <DetailTableRowCell titleWidth={"150px"} title="미납 패널티">
              패널티
            </DetailTableRowCell>
          </RowWrapper>
          <RowWrapper>
            <DetailTableRowCell titleWidth={"150px"} title="해외 판매 동의 설정">
              {replaceBooleanValueToString(user.globalSale)}
            </DetailTableRowCell>
            <DetailTableRowCell titleWidth={"150px"} title="통관고유부호">
              <CellContentRowWrapper>
                {user.customsCode || "-"}
                <ButtonWrapper>
                  <Button
                    buttonStyle="blue-fill"
                    buttonSize="S"
                    onClick={() => setIsUserCustomsCodeChangeModalOpen(true)}
                  >
                    관리
                  </Button>
                </ButtonWrapper>
              </CellContentRowWrapper>
            </DetailTableRowCell>
          </RowWrapper>
          <DetailTableRowCell titleWidth="150px" title="메모">
            <CellContentRowWrapper>
              <MemoListWRapper>
                {!!user.memos.length &&
                  user.memos.map((memoObject) => <MemoWrapper key={memoObject.id}>{memoObject.memo}</MemoWrapper>)}
              </MemoListWRapper>
              <ButtonWrapper>
                <Button buttonStyle="blue-fill" buttonSize="S" onClick={() => setIsUserMemoChangeModalOpen(true)}>
                  관리
                </Button>
              </ButtonWrapper>
            </CellContentRowWrapper>
          </DetailTableRowCell>
        </ColumnWrapper>
      </DetailTableBody>
      <UserStatusChangeModal isOpen={isUserStatusModalOpen} closeModal={() => setIsUserStatusModalOpen(false)} />
      <UserNickNameChangeModal
        isOpen={isUserNicnameChangeModalOpen}
        closeModal={() => setIsUserNicknameChangeModalOpen(false)}
        changeNickname={changeNickname}
        nickname={user.nickname || ""}
      />
      <UserPhonenNumberChangeModal
        isOpen={isUserPhoneNumberChangeModalOpen}
        closeModal={() => setIsUserPhoneNumberChangeModalOpen(false)}
        changePhoneNumber={changePhoneNumber}
        phoneNumber={user.phone || ""}
      />
      <UserMemoChangeModal
        isOpen={isUserMemoChangeModalOpen}
        closeModal={() => setIsUserMemoChangeModalOpen(false)}
        saveMemo={saveMemo}
        deleteMemo={deleteMemo}
        memos={user.memos}
      />
      <CustomsCodeChangeModal
        user={user}
        isOpen={isUserCustomsCodeChangeModalOpen}
        closeModal={() => setIsUserCustomsCodeChangeModalOpen(false)}
        setUserDetail={setUserDetail}
        customsCode={user.customsCode || ""}
      ></CustomsCodeChangeModal>
    </>
  )
}

export default UserInformationTable

const TitleWrapper = styled.div`
  padding: 10px;
  background: #eee;
  border-bottom: 1px solid #ccc;
  font-weight: 800;
`

const RowWrapper = styled.div`
  width: 100%;
  display: inline-flex;

  :not(:last-child) {
    border-bottom: 1px solid #ccc;
  }
`

const ColumnWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ButtonWrapper = styled.div`
  width: 50px;
`

const SuperLoginButtonWrapper = styled.div`
  width: 140px;
`

const SuperLoginInputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
`

const CellContentRowWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
`

const MemoListWRapper = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
`

const MemoWrapper = styled.div`
  flex: 1;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;

  :not(:last-child) {
    margin-bottom: 4px;
  }
`
