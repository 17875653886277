import { BuyOrderStatusCode, BuyOrderStatusGroupType } from "./BuyOrderType"
import { ProductType } from "./ProductType"
import { OrderType, SellOrderStatusCode, SellOrderStatusGroupType } from "./SellOrderType"
import { AdminUserType } from "./AdminUserType"

export type UserStatusType = "활성" | "제한" | "휴면" | "탈퇴" | "임시"

export type UserAuthType = "애플" | "카카오" | "이메일"
export type UserGenderType = "M" | "F"
export declare interface UserType {
  id: number
  status: UserStatusType
  authType: UserAuthType
  name?: string
  nickname?: string
  carrier?: string
  phone?: string
  email: string
  birth?: string
  gender?: UserGenderType
  receiveOrderPush: boolean
  receiveInterestedItemPush: boolean
  lastLoginAt?: string
  signupAt?: string
  hasMemo: boolean
  userType?: UserTypeCode
  foreigner?: boolean
  globalSale?: boolean
}

export interface UserDetailType {
  id: number
  status: UserStatusType
  authType: UserAuthType
  name?: string
  nickname?: string
  carrier?: string
  phone?: string
  email: string
  birth?: string
  gender?: UserGenderType
  lastLoginAt?: string
  signupAt?: string
  address?: AddressType
  hasViverPayDefaultCard: boolean
  receiveInterestedItemPush: boolean
  receiveOrderPush: boolean
  receiveAdPush: boolean
  bankAccount?: BankAccountType
  termsAgreement: TermsAgreementType[]
  dormantScheduledDate?: string
  memos: UserMemoType[]
  globalSale: boolean
  customsCode?: string
}

export interface AddressType {
  id: number
  userName: string
  phone: string
  defaultAddress: boolean
  zipCode: string
  address: string
  addressDetail: string
}

export interface BankAccountType {
  id: number
  bank: BankType
  default: boolean
  accountNumber: string
  accountHolder: string
}

export interface BankType {
  code: string
  name: string
  displayName: string
  logoImageUrl: string
}

export interface TermsAgreementType {
  terms: TermsType
  agree: boolean
  createdAt: string
  updatedAt: string
}

export type KindOfTermsType = "나이확인" | "이용약관" | "개인정보" | "프로모션"

export type TermsAgreeChannel = "앱 푸시" | "이메일" | "문자 메세지"

export interface TermsType {
  id: number
  type: KindOfTermsType
  title: string
  notifyDate: string
  startDate: string
  required: boolean
  endDate?: string
  subtitle?: TermsAgreeChannel
}

export const enum UserMemoTargetTypeCode {
  USER = "USER",
  PRODUCT = "PRODUCT",
  SERIAL_NO = "SERIAL_NO",
}

export interface UserMemoType {
  id: number
  orderId?: string | null
  productId?: number | null
  shippingId?: number | null
  paymentId?: number | null
  serialNo?: string
  userId: number
  memo: string
  createdAt: string
  createdBy: AdminUserType
  updatedAt: string
  updatedBy: AdminUserType
}

export interface CreateUserMemoType {
  orderId?: string | null
  productId?: number | null
  shippingId?: number | null
  paymentId?: number | null
  serialNo?: string
  userId?: number | null
  targetType?: UserMemoTargetTypeCode
  memo: string
}

export type UserListSearchParams = {
  id?: number
  email?: string
  nickname?: string
  name?: string
  phone?: string
  foreigner?: string
  status?: string
  size?: number
  page?: number
  userType?: UserTypeCode
}

export interface UserSellOrderType {
  id: string
  sellOrderType: OrderType
  seller: UserType
  sellOrderStatus: SellOrderStatusCode
  sellOrderStatusGroup: SellOrderStatusGroupType
  product: ProductType
}

export interface UserBuyOrderType {
  id: string
  sellOrderType: OrderType
  buyer: UserType
  buyOrderStatus: BuyOrderStatusCode
  buyOrderStatusGroup: BuyOrderStatusGroupType
  product: ProductType
}

export const enum UserTypeCode {
  개인 = "개인",
  개인사업자 = "개인사업자",
  법인사업자 = "법인사업자",
}

export const UserTypeCodeList: { value: UserTypeCode; title: string }[] = [
  {
    value: UserTypeCode.개인,
    title: "개인(일반)",
  },
  {
    value: UserTypeCode.개인사업자,
    title: "개인(사업자)",
  },
  {
    value: UserTypeCode.법인사업자,
    title: "법인(사업자)",
  },
]

export interface SuperLoginHistory {
  id: number
  adminUserId: number
  adminUserName: string
  reason: string
  loginedAt: string
}
